<template>
  <el-dialog
    :visible.sync="visible"
    width="690px"
    title="添加课程"
    class="classDialog"
    v-model="dataForm1"
    ref="addOrAdd"
    @close="clearData"
  >
    <div class="form-list">
      <el-form :model="dataForm1" ref="dataFormRef1" :rules="rules">
        <div class="item">
          <span class="inputItem-title"><i>*</i>课程标题</span>
          <el-form-item label="" prop="name">
            <el-input
              v-model="dataForm1.name"
              placeholder="请输入课程标题"
            ></el-input>
          </el-form-item>
        </div>
        <div class="item">
          <span class="inputItem-title"><i>*</i>选择所属年级</span>
          <el-form-item prop="gradeName">
            <el-select
              v-model="dataForm1.gradeName"
              placeholder="请选择"
              @change="changeSelect"
            >
              <el-option
                v-for="item in gradeType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="item">
          <span class="inputItem-title"><i>*</i>选择章节</span>
          <el-form-item prop="subName">
            <el-select
              v-model="dataForm1.subName"
              placeholder="请选择"
              @change="changeSelectChapter"
            >
              <el-option
                v-for="item in chapter"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="item">
          <span class="inputItem-title"><i>*</i>课程封面</span>
          <el-form-item label="" prop="pic" ref='choosePic'>
            <div class="file-content">
              <div class="file">
                <div>
                  <el-upload
                    class="avatar-uploader"
                    action=""
                    :auto-upload="false"
                    accept="image/*"
                    :on-change="toUpload"
                    :show-file-list="false"
                    ref="mainUploadPic"
                  >
                    <div class="avatar" v-if="dataForm1.pic">
                      <img :src="dataForm1.pic" />
                    </div>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </div>
              <p class="attion">注：单次上传图片大小要求不超过1M。</p>
            </div>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="" prop="price">
            <span class="inputItem-title"><i>*</i>课程价格</span>
            <el-input
              v-model="dataForm1.price"
              placeholder="请填写课程价格"
            ></el-input>
          </el-form-item>
        </div>
        <div class="add-btn">
          <el-button @click="addCourse('dataFormRef1')">确认添加</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script src="https://avuex.avue.top/cdn/aliyun-oss-sdk.min.js"></script>
<script>
import { getSuffix } from "@/utils";
const OSS = require("ali-oss");
export default {
  data() {
    return {
      //图片选择
      percentage: 0,
      uploadFilesName: "",
      uploadfile: [],
      visible: false,
      imgSize: 1, //图片大小设置
      data: [],
      gradeType: [],
      chapter: [],
      gradename: "",
      sub: "",
      rules: {
        name: [{ required: true, message: "请输入课程标题", trigger: "blur" }],
        gradeName: [
          { required: true, message: "选择所属年级", trigger: "blur" },
        ],
        subName: [{ required: true, message: "选择所属章节", trigger: "blur" }],
        //pic: [{ required: true, message: "选择上传图片", }],
        price: [{ required: true, message: "请输入课程价格", trigger: "blur" }],
      },
      dataForm1: {
        userId: "",
        token: "",
        id: 0,
        name: "",
        pic: "",
        subId: "1111",
        gradeId: "",
        gradeName: "",
        userName: "",
        price: '',
        lon: "",
        lat: "",
        citys: '',
        remarks: "",
        section: '',
        subName: "",
      },
    };
  },
  created() {
    this.dataForm1.token = window.localStorage.getItem("token");
    this.dataForm1.userId = window.localStorage.getItem("userId");
    this.dataForm1.section = window.localStorage.getItem("section");
    this.init();
    this.getGradeType();
  },
  methods: {
    init() {
      this.visible = true;
    },
    addCourse(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid){
           this.$axios({
          method: "post",
          params: {
            token: this.dataForm1.token,
            name: this.dataForm1.name,
            pic: this.dataForm1.pic,
            subId: this.dataForm1.subId,
            userId: this.dataForm1.userId,
            price: this.dataForm1.price,
            lon: this.dataForm1.lon,
            lat: this.dataForm1.lat,
            city: this.dataForm1.city,
          },
          url: `/teacher/catalog/insert`,
        }).then((res) => {
          if (res.data.code !== "0000") {
            return this.$message.error(res.data.message);
          }
          this.visible = false;
          this.dataForm1 = "";
          this.$emit("refreshDataList");
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
        });
        }
      });
    },
    clearData() {
      this.$refs.dataFormRef.resetFields();
    },
    //获取年级列表
    getGradeType() {
      this.gradeType = [];
      this.$axios({
        url: "/teacher/grade/grades",
        params: {
          type: this.dataForm1.section,
        },
        method: "post",
      })
        .then((res) => {
          for (let item of res.data.data.grades) {
            this.gradeType.push({
              value: item.id,
              label: item.name,
              type: item.typeNmae,
            });
          }
        })
        .catch(() => {});
    },
    //年级下拉选择
    changeSelect(val) {
      this.chapterType(val);
    },
    //获取章节列表
    chapterType(val) {
      this.chapter = [];
      this.$axios({
        url: "/teacher/grade/getChapte",
        params: {
          type: 2,
          gradeId: val,
          userId: this.dataForm1.userId,
        },
        method: "post",
      })
        .then((res) => {
          if (res.data.code !== "0000") {
            return this.$message.error(res.data.message);
          }
          console.log(res);
          for (let item of res.data.data.chapter) {
            this.chapter.push({
              value: item.id,
              label: item.name,
              id: item.subjectId,
            });
          }
        })
        .catch(() => {});
    },
    changeSelectChapter(val) {
      this.dataForm1.subId = val;
    },
    toUpload(file) {
      const client = OSS({
        region: this.Common.region,
        endpoint: this.Common.endpoint,
        accessKeyId: this.Common.accessKeyId,
        accessKeySecret: this.Common.accessKeySecret,
        bucket: this.Common.bucket,
      });
      if (file.raw) {
        const that = this;
        let consat = file.name;
        let suffix = getSuffix(consat);
        let name = Date.now() + suffix;
        let size = file.size / 1024 / 1024;
        if (size > this.imgSize) {
          return this.$message({
            message: "图片大于" + this.imgSize + "M",
            type: "warning",
          });
        }
        let ret = client
          .multipartUpload(this.Common.warename + name, file.raw, {
            progress: function (p) {
              let num = p * 100;
              that.percentage = num;
            },
          })
          .then((res) => {
            this.dataForm1.pic = res.res.requestUrls[0];
            let indexof = this.dataForm1.pic.lastIndexOf("?");
            if (indexof > -1) {
              this.dataForm1.pic = this.dataForm1.pic.substring(0, indexof);
            }
          })
          .catch((err) => {
            console.log("上传失败");
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>
</style>