<template>
  <div>
    <div class="search">
      <!-- 搜索筛选 -->
      <el-form :inline="true" class="class-search">
        <el-form-item label="课程名称" class="search-name">
          <el-input
            size="small"
            placeholder="按课程名称"
            v-model="params.name"
            @input="inputChange"
          ></el-input>
        </el-form-item>

        <!-- //日期 -->
        <div class="search-date">
          <span>时间范围</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeDate"
            ref="pickerRef"
          >
          </el-date-picker>
        </div>
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="addOrAddHandle"
          >添加课程</el-button
        >
      </el-form>
    </div>
    <div class="class-tab">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="number" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="pic" label="封图">
          <template slot-scope="scope">
            <img :src="scope.row.pic" min-width="100" height="50" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="课程标题" width="120">
        </el-table-column>
        <el-table-column prop="gradeName" label="所属年级" sortable>
        </el-table-column>
        <el-table-column prop="subName" label="章节"> </el-table-column>
        <el-table-column prop="kmName" label="科目"> </el-table-column>
        <el-table-column prop="price" label="金额"> </el-table-column>
        <el-table-column prop="addTime" label="上传时间" width="182" sortable>
        </el-table-column>
        <el-table-column prop="comments" label="评论数量"> </el-table-column>
        <el-table-column prop="volume" label="销售量" width="74">
        </el-table-column>
        <el-table-column prop="courseNum" label="课时" width="86">
        </el-table-column>
        <el-table-column label="操作" width="170">
          <template slot-scope="scope">
            <!-- <el-button size="mini" @click="goDetail"
                      >查看课时</el-button
                    > -->
            <a
              href="javascript:;"
              @click="goDetail(scope.row.id)"
              class="look-btn"
              >添加课时</a
            >
            <a
              href="javascript:;"
              @click="addOrUpdateHandle(scope.row.id)"
              class="edit-btn"
              >编辑</a
            >
            <a
              href="javascript:;"
              @click="removeClassById(scope.row.id)"
              class="delete-btn"
              >删除</a
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
        v-if="tableData.length"
      >
      </el-pagination>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getData"
    ></add-or-update>

    <add-or-add
      v-if="addOrAddVisible"
      ref="addOrAdd"
      @refreshDataList="getData"
    ></add-or-add>
  </div>
</template>

<script>
import AddOrUpdate from "./class-add-or-update";
import AddOrAdd from "./class-add-or-add";
export default {
  name: "class",
  props: ["fileList", "imageMax"],
  components: {
    AddOrUpdate, //编辑
    AddOrAdd, //添加
  },
  data() {
    return {
      value1: "",
      value2: "",
      addOrUpdateVisible: false,
      addOrAddVisible: false,
      //表格
      tableData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
      },
      total: 0,
      params: {
        workUserId: "",
        token: "",
        name: "",
        startTime: "",
        endTime: "",
        catalogId:'',
        userId:''
      },
    };
  },
  created() {
    this.params.token = window.localStorage.getItem("token");
    this.params.workUserId = window.localStorage.getItem("userId");
    this.params.userId = window.localStorage.getItem("userId");
    this.getData();
  },
  methods: {
    goDetail(id) {
      this.$router.push({ name: "classList", query: { id: id } });
    },
    //  修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    //新增
    addOrAddHandle() {
      this.addOrAddVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrAdd.init();
      });
    },
    getData(page) {
      this.$axios({
        method: "post",
        params: Object.assign(
          {
            current: page == null ? this.page.current : page.currentPage,
            size: page == null ? this.page.size : page.pageSize,
          },
          this.params
        ),
        url: "/teacher/catalog/list",
      }).then((res) => {
        if (res.data.code !== "0000") {
          return this.$message.error(res.data.message);
        }
        this.tableData = res.data.data.catalogs.records;
        this.total = res.data.data.catalogs.total;
      });
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.page.size = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.page.current = newSize;
      this.getData();
    },

    inputChange() {
      this.getData(this.page);
    },
    changeDate() {
      var val = this.$refs.pickerRef.displayValue;
      var startTime = val[0];
      var endTime = val[1];
      this.params.startTime = startTime;
      this.params.endTime = endTime;
      this.getData();
    },


    //删除操作
    async removeClassById(id) {
      this.params.catalogId = id;
      const confirmResult = await this.$confirm(
        "此操作将永久删除该作品, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$axios({
        method: "post",
        params: this.params,
        url: `/teacher/catalog/delete`,
      });
      if (res.data.code == "0000") {
        this.getData();
        return this.$message.info("删除成功");
      }
    },


  },
};
</script>