<template>
  <el-dialog
    :visible.sync="visible"
    width="690px"
    title="添加课程"
    class="classDialog"
    v-model="dataForm"
    ref="addOrUpdate"
    @close="clearData"
  >
    <div class="form-list">
      <el-form :model="dataForm" ref="dataFormRef" :rules="rules">
        <div class="item">
          <span class="inputItem-title"><i>*</i>课程标题</span>
          <el-form-item label="" prop="name">
            <el-input
              v-model="dataForm.name"
              placeholder="请输入课程标题"
            ></el-input>
          </el-form-item>
        </div>
        <div class="item">
          <span class="inputItem-title"><i>*</i>选择所属年级</span>
          <el-form-item prop="gradeName">
            <el-select
              v-model="gradename"
              placeholder="请选择"
              @change="gradeSelect"
            >
              <el-option
                v-for="item in gradeType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="item">
          <span class="inputItem-title"><i>*</i>选择章节</span>
          <el-form-item prop="subName">
            <el-select
              v-model="dataForm.subName"
              placeholder="请选择"
              @change="changeSelectChapter"
            >
              <el-option
                v-for="item in chapter"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="item">
          <span class="inputItem-title"><i>*</i>课程封面</span>
          <el-form-item label="" prop="pic">
            <div class="file-content">
              <div class="file">
                <div>
                  <el-upload
                    class="avatar-uploader"
                    action=""
                    :auto-upload="false"
                    accept="image/*"
                    :on-change="toUpload"
                    :show-file-list="false"
                    ref="mainUploadPic"
                  >
                    <div class="avatar" v-if="dataForm.pic">
                      <img :src="dataForm.pic" />
                    </div>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </div>
              <p class="attion">注：单次上传图片大小要求不超过1m。</p>
            </div>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="" prop="price">
            <span class="inputItem-title"><i>*</i>课程价格</span>
            <el-input
              v-model="dataForm.price"
              placeholder="请填写课程价格"
            ></el-input>
          </el-form-item>
        </div>
        <div class="add-btn">
          <el-button @click="changeClass('dataFormRef')">确认修改</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script src="https://avuex.avue.top/cdn/aliyun-oss-sdk.min.js"></script>
<script>
import { getSuffix } from "@/utils";
const OSS = require("ali-oss");
export default {
  data() {
    return {
      //图片选择
      percentage: 0,
      uploadFilesName: "",
      uploadfile: [],
      visible: false,
      imgSize: 1, //图片大小设置
      data: [],
      gradeType: [],
      chapter: [],
      gradename: "",
      subject: "",
      section: "",
      userId: 0,
      token: "",
      catalogId: "", //
      rules: {
        name: [{ required: true, message: "请输入课程标题", trigger: "blur" }],
        gradeName: [
          { required: true, message: "请选择所属年级", trigger: "blur" },
        ],
        subName: [{ required: true, message: "请选择章节", trigger: "blur" }],
        price: [{ required: true, message: "请输入课程价格", trigger: "blur" }],
      },
      dataForm: {
        id: 0,
        addTime: "",
        name: "",
        pic: "",
        subId: "",
        userId: "",
        gradeId: "",
        gradeName: "",
        kmId: "",
        kmName: "",
        userName: "",
        userPic: "",
        price: 0,
        volume: 0,
        courseNum: 0,
        courseTypeNum: 0,
        lon: "",
        lat: "",
        city: 0,
        cityName: "",
        type: "2",
        subName: "",
      },
    };
  },
  created() {
    this.token = window.localStorage.getItem("token");
    this.userId = window.localStorage.getItem("userId");
    this.section = window.localStorage.getItem("section");
  },
  methods: {
    init(id) {
      this.visible = true;
      this.catalogId = id;
      if (this.catalogId) {
        this.$axios({
          method: "post",
          params: {
            userId: this.userId,
            token: this.token,
            catalogId: this.catalogId,
          },
          url: `/teacher/catalog/info`,
        }).then((res) => {
          console.log(res);
          if (res.data.code !== "0000") {
            return this.$message.error(res.data.message);
          }
          this.dataForm = res.data.data.catalog;
          this.gradename = this.dataForm.gradeName;
          this.getGradeType();
          this.chapterType(this.dataForm.gradeId);
        });
      }
    },
    clearData() {},
    //修改课程
    changeClass(formName) {
      console.log(this.dataForm.subId);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            params: {
              token: this.token,
              userId: this.userId,
              id: this.catalogId,
              name: this.dataForm.name,
              pic: this.dataForm.pic,
              subId: this.dataForm.subId,
              price: this.dataForm.price,
            },
            url: `/teacher/catalog/update`,
          }).then((res) => {
            if (res.data.code !== "0000") {
              return this.$message.error(res.data.message);
            }
            this.visible = false;
            this.$emit("refreshDataList");
            this.$message({
              showClose: true,
              message: "修改成功",
              type: "success",
            });
          });
        }
      });
    },

    //获取年级列表
    getGradeType() {
      this.gradeType = [];
      this.$axios({
        url: "/teacher/grade/grades",
        params: {
          type: this.section,
        },
        method: "post",
      })
        .then((res) => {
          for (let item of res.data.data.grades) {
            this.gradeType.push({
              value: item.id,
              label: item.name,
              type: item.typeNmae,
            });
          }
        })
        .catch(() => {});
    },
    //年级下拉选择
    gradeSelect(val) {
      this.dataForm.subName = "";
      this.chapterType(val);
    },
    //获取章节列表
    chapterType(id) {
      this.chapter = [];
      this.$axios({
        url: "/teacher/grade/getChapte",
        params: {
          type: 2,
          gradeId: id,
          userId: this.dataForm.userId,
        },
        method: "post",
      })
        .then((res) => {
          //console.log(res)
          if (res.data.code !== "0000") {
            return this.$message.error(res.data.message);
          }
          for (let item of res.data.data.chapter) {
            this.chapter.push({
              value: item.id,
              label: item.name,
              id: item.subjectId,
            });
          }
        })
        .catch(() => {});
    },
    changeSelectChapter(val) {
      this.dataForm.subId = val;
      console.log(this.dataForm.subId);
    },

    toUpload(file) {
      const client = OSS({
        region: this.Common.region,
        endpoint: this.Common.endpoint,
        accessKeyId: this.Common.accessKeyId,
        accessKeySecret: this.Common.accessKeySecret,
        bucket: this.Common.bucket,
      });
      if (file.raw) {
        const that = this;
        let consat = file.name;
        let suffix = getSuffix(consat);
        let name = Date.now() + suffix;
        let size = file.size / 1024 / 1024;
        if (size > this.imgSize) {
          return this.$message({
            message: "图片大于" + this.imgSize + "M",
            type: "warning",
          });
        }
        let ret = client
          .multipartUpload(this.Common.warename + name, file.raw, {
            progress: function (p) {
              let num = p * 100;
              that.percentage = num;
            },
          })
          .then((res) => {
            this.dataForm.pic = res.res.requestUrls[0];
            let indexof = this.dataForm.pic.lastIndexOf("?");
            if (indexof > -1) {
              this.dataForm.pic = this.dataForm.pic.substring(0, indexof);
            }
          })
          .catch((err) => {
            console.log("上传失败");
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>
</style>